import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import PropTypes from 'prop-types';
import {
  Form, TextArea, Container, Button, Icon, Segment, Grid, Checkbox, Divider,
} from 'semantic-ui-react';
import noteTypes from '../../NoteTypes';
import './SingleNoteContent.css';

/*
  The purpose of this Component is to make the difference
  between a listNote and a freeTextNote more visible to
  the editing programmer
  it handles all changes to the content to the note only
*/
class SingleNoteContent extends React.Component {
  constructor(props) {
    super(props);
    const {
      editMode, noteType, noteContent, onChange, onDirectChange,
    } = this.props;
    this.state = {
      editMode,
      noteType,
      noteContent,
      onChange,
      onDirectChange,
    };
    this.focusListItems = [];

    this.onTextValueChange = this.onTextValueChange.bind(this);
    this.changeTypeToFreeText = this.changeTypeToFreeText.bind(this);
    this.changeTypeToCheckList = this.changeTypeToCheckList.bind(this);
    this.onListValueChange = this.onListValueChange.bind(this);
    this.createNewListItem = this.createNewListItem.bind(this);
    this.onCheckBoxChanged = this.onCheckBoxChanged.bind(this);
    this.onCheckBoxChangedByClick = this.onCheckBoxChangedByClick.bind(this);
    this.onListItemKeyPress = this.onListItemKeyPress.bind(this);
    this.deleteListItem = this.deleteListItem.bind(this);
  }

  // Syncs the Text Field with the Component state
  // Syncs the Component state with the parent component
  onTextValueChange(e) {
    const { noteContent, onChange } = this.state;
    Object.assign(noteContent, { noteText: e.target.value });
    this.setState({ noteContent }, () => {
      /* OnChange propagates the current state to the Parent */
      onChange(this.state);
    });
  }

  // Syncs the List Note with the Component state
  // Syncs the Component state with the parent component
  onListValueChange(e) {
    const InputId = e.target.id;
    const index = parseInt(InputId.split('::')[3], 10);
    const newText = e.target.value;

    const { noteContent, onChange } = this.state;
    const newNoteContent = noteContent;
    noteContent.noteCheckListContent[index].listText = newText;
    this.setState({ noteContent: newNoteContent }, () => {
      onChange(this.state);
    });
  }

  // Fires when a checkBox is ticked or unticked
  // Syncs the Checkbox with the state of the component
  // Syncs the Component state with the parent component
  onCheckBoxChanged(e) {
    const checkBoxId = e.target.id;
    const index = parseInt(checkBoxId.split('::')[3], 10);
    const completed = e.target.checked;

    const { noteContent, onChange } = this.state;
    const newNoteContent = noteContent;
    noteContent.noteCheckListContent[index].completed = completed;
    this.setState({ noteContent: newNoteContent }, () => {
      onChange(this.state);
    });
  }

  // This is fired when the Note is not in edit mode and a checkBox is directly clicked
  // Propagates changes directly to the parent Component
  onCheckBoxChangedByClick(index, event) {
    /* Prevent other events from interfering */
    if (event.type !== 'click') {
      return;
    }

    const { noteContent, onDirectChange } = this.state;
    const newNoteContent = noteContent;
    noteContent.noteCheckListContent[index].completed = !noteContent
      .noteCheckListContent[index].completed;
    this.setState({ noteContent: newNoteContent }, () => {
      onDirectChange(this.state);
    });
  }

  /*
    The purpose of this function is to increase usability:
    - If the User preses 'Enter' a new List Item is created
    - If the user creates Backspace on an empty List Item it is removed
  */
  onListItemKeyPress(e, i) {
    if (e.key === 'Enter') {
      this.createNewListItem();
    } else if (e.key === 'Delete' || e.key === 'Backspace') {
      const { noteContent } = this.state;
      if (noteContent.noteCheckListContent[i].listText.length === 0) {
        this.deleteListItem(i);
      }
    }
  }

  /*
    The purpose of this function is to increase usability:
    - Does not delete the list item if it's the only one available
    - focuses the locically next or previous list item if one is deleted
  */
  deleteListItem(index) {
    const { noteContent, onChange } = this.state;
    if (noteContent.noteCheckListContent.length === 1) {
      // Don't delete Item 0
      return;
    }
    noteContent.noteCheckListContent.splice(index, 1);
    this.setState({ noteContent }, () => {
      /* Don't focus null */
      if (index > 0) {
        this.focusListItems[index - 1].focus();
      }
      onChange(this.state);
    });
  }

  // Handles and Propages when the Note type 'freeText' is selected
  // Speciality: This propages directly to the top most component
  changeTypeToFreeText() {
    const { onDirectChange } = this.state;
    this.setState({ noteType: noteTypes.freeText }, () => {
      /* DirectChange directly propages changes to the top most component */
      onDirectChange(this.state);
    });
  }

  // Handles and Propages when the Note type 'listType' is selected
  // Speciality: This propages directly to the top most component
  changeTypeToCheckList() {
    const { onDirectChange } = this.state;
    this.setState({ noteType: noteTypes.checkList }, () => {
      /* DirectChange directly propages changes to the top most component */
      onDirectChange(this.state);
    });
  }

  /*
    The purpose of this function is to increase usability:
    - focuses the newly created list type
  */
  // Propagate changes to the parent component
  createNewListItem() {
    const { noteContent, onChange } = this.state;
    const newListItem = {
      completed: false,
      listText: '',
    };
    noteContent.noteCheckListContent.push(newListItem);
    this.setState({ noteContent }, () => {
      this.focusListItems[this.focusListItems.length - 1].focus();
      onChange(this.state);
    });
  }

  render() {
    const { noteContent, noteType } = this.state;

    const {
      editMode, currentFontColor, currentBackgroundColor, currentFontSizeModifier, noteId,
    } = this.props;
    // Replaces Blank Text with placeholders
    const replacedNoteText = noteContent.noteText
      ? noteContent.noteText.split('\n').map(txt => <p className="noteText" key={`key::${txt}`}>{txt}</p>)
      : (
        <div className="ui placeholder">
          <div className="paragraph">
            <div className="line" style={{ backgroundColor: currentBackgroundColor }} />
            <div className="line" style={{ backgroundColor: currentBackgroundColor }} />
            <div className="line" style={{ backgroundColor: currentBackgroundColor }} />
            <div className="line" style={{ backgroundColor: currentBackgroundColor }} />
            <div className="line" style={{ backgroundColor: currentBackgroundColor }} />
          </div>
        </div>
      );

    const listCheckRows = [];
    this.focusListItems = [];
    if (noteType === noteTypes.checkList) {
      if (editMode) {
        listCheckRows.push(<Divider hidden />);
        for (let i = 0; i < noteContent.noteCheckListContent.length; i += 1) {
          listCheckRows.push(
            <Container>
              <Grid columns={2}>
                <Grid.Row style={{ paddingTop: '0' }}>
                  <Grid.Column width={1}>
                    <Checkbox
                      label=""
                      onChange={this.onCheckBoxChanged}
                      defaultChecked={noteContent.noteCheckListContent[i].completed}
                      id={`${noteId}::CheckBox::${i}`}
                      style={{ verticalAlign: 'middle' }}
                    />
                  </Grid.Column>
                  <Grid.Column width={14}>
                    <div className="ui input transparent" style={{ color: currentFontColor, verticalAlign: 'middle', width: '90%' }}>
                      <input
                        style={{ color: currentFontColor }}
                        value={noteContent.noteCheckListContent[i].listText}
                        placeholder="Add a new Todo..."
                        id={`${noteId}::Input::${i}`}
                        onChange={this.onListValueChange}
                        onKeyUp={e => this.onListItemKeyPress(e, i)}
                        ref={(input) => { if (input) this.focusListItems.push(input); }}
                      />
                    </div>
                    {i !== 0 && (
                      <Icon name="delete" onClick={() => this.deleteListItem(i)} />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>,
          );
        }
        listCheckRows.push(
          <Container>
            <Divider hidden />
            <Button circular size="mini" className="createNewListItem" onClick={this.createNewListItem} style={{ width: '100%' }}>
              <Icon circular name="plus circle" />
            </Button>
          </Container>,
        );
      } else {
        for (let i = 0; i < noteContent.noteCheckListContent.length; i += 1) {
          listCheckRows.push(
            <Container key={`noteCheckListContainer::${noteId}::${i}`}>
              <Checkbox
                defaultChecked={noteContent.noteCheckListContent[i].completed}
                onClick={
                  /* Prevents editMode from beeing entered when CheckBox is clicked */
                  (e) => {
                    e.stopPropagation();
                    this.onCheckBoxChangedByClick(i, e);
                  }}
                key={`noteCheckListCheckbox::${noteId}::${i}`}
                label={(
                  <label
                    style={{ color: currentFontColor, fontSize: `${currentFontSizeModifier}em` }}
                    key={`noteCheckListLabel::${noteId}::${i}`}
                  >
                    {noteContent.noteCheckListContent[i].listText}
                  </label>
                )}
              />
            </Container>,
          );
        }
      }
    }

    return (
      <Container>
        {(noteType === noteTypes.new)
          && (
            <Segment basic>
              <Grid columns={1} textAlign="center">
                <Grid.Row verticalAlign="middle">
                  <Button.Group size="massive">
                    <Button onClick={this.changeTypeToFreeText}>
                      <Icon name="align justify" />
                    </Button>
                    <Button.Or />
                    <Button onClick={this.changeTypeToCheckList}>
                      <Icon.Group>
                        <Icon name="unordered list" />
                        <Icon corner="bottom right" name="check square" />
                      </Icon.Group>
                    </Button>
                  </Button.Group>
                </Grid.Row>
              </Grid>
            </Segment>
          )
        }
        {(editMode && noteType === noteTypes.freeText)
          && (
            <Form>
              <TextArea
                autoHeight
                value={noteContent.noteText}
                placeholder="What are you thinking?"
                onChange={this.onTextValueChange}
                style={{
                  color: currentFontColor, backgroundColor: currentBackgroundColor, fontSize: `${currentFontSizeModifier}em`, padding: '0', border: '0px',
                }}
              />
            </Form>
          )
        }
        {(!editMode && noteType === noteTypes.freeText)
          && (
            replacedNoteText
          )
        }
        {noteType === noteTypes.checkList
          && (
            listCheckRows
          )
        }

      </Container>
    );
  }
}

SingleNoteContent.defaultProps = {
  editMode: true,
  noteType: noteTypes.new,
  noteContent: {},
  currentFontColor: '#ffffff',
  currentBackgroundColor: '#000000',
  currentFontSizeModifier: 1,
};

SingleNoteContent.propTypes = {
  editMode: PropTypes.bool,
  noteType: PropTypes.string,
  noteId: PropTypes.string.isRequired,
  noteContent: PropTypes.shape({ noteHeading: PropTypes.string }),
  onChange: PropTypes.func.isRequired,
  onDirectChange: PropTypes.func.isRequired,
  currentFontColor: PropTypes.string,
  currentBackgroundColor: PropTypes.string,
  currentFontSizeModifier: PropTypes.number,
};


export default SingleNoteContent;
