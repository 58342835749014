import React from 'react';
import './Login.css';
import {
  Icon, Form, Input, Button, Grid, Container, Divider, Segment,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import PasswordStrengthIndicator from '../PasswordStrengthIndicatorComponent/PasswordStrengthIndicator';
import u2fLogo from '../../u2f.png';

/*
  The Purpose of this Component is to provide a View that
  allows the user to login into the application
*/
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inLogin: false,
      username: '',
      password: '',
      inRegister: false,
      inLoading: false,
      userObject: {},
      loginErrored: false,
      specificLoginError: '',
      registerErrored: false,
      specificRegisterError: '',
      passwordIsFocused: false,
    };

    // binding functions allows access to 'this' inside the function
    this.switchToLoginState = this.switchToLoginState.bind(this);
    this.switchToRegisterState = this.switchToRegisterState.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.doLoginLocally = this.doLoginLocally.bind(this);
    this.doLoginServerside = this.doLoginServerside.bind(this);
    this.doRegisterLocally = this.doRegisterLocally.bind(this);
    this.goBack = this.goBack.bind(this);
    this.passwordFocused = this.passwordFocused.bind(this);
    this.passwordBlured = this.passwordBlured.bind(this);
  }

  // Changes the 'Register or Login' View to display the Login Form
  switchToLoginState() {
    this.setState({ inLogin: true });
  }

  // Changes the 'Register or Login' View to display the Register Form
  switchToRegisterState() {
    this.setState({ inRegister: true });
  }

  /* Those changeListeners synchronise the values of the Input fields with the component state */

  handleUsernameChange(e) {
    this.setState({ username: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  // Changes the Register Form and Login Form to display the 'Register or Login' View
  goBack() {
    this.setState({
      inRegister: false,
      inLogin: false,
    });
  }

  /*
    Registration is done remote and locally
    the remote method sends the prepared data to the server
    to fetch the user object
  */
  async doLoginServerside() {
    const { username, password, userObject } = this.state;
    // MOCK
    // Call Login
    await new Promise(resolve => setTimeout(resolve, 2000));
    console.log(`Logged in as ${username};${password}`);
    Object.assign(userObject, { username, password });
    await this.setState({ userObject });
    // MOCK

    const { onSuccessfulLogin } = this.props;
    onSuccessfulLogin(userObject);
    console.log(`Logged in as ${JSON.stringify(userObject)}`);
    return true;
  }

  /*
    local Login checks for common errors and then waits
    for the Server to confirm the login
    Possible errors from the server are catched and handled here
  */
  async doLoginLocally() {
    const { username } = this.state;
    if (username.length === 0) {
      this.setState({ loginErrored: true, specificLoginError: 'Username is empty' });
      return;
    }
    this.setState({ inLoading: true });
    const loginResult = await this.doLoginServerside();
    this.setState({ inLoading: false });
    if (!loginResult) {
      this.setState({ loginErrored: true });
      return;
    }
    const { history } = this.props;
    this.setState({ inLogin: false });
    history.push('/notes');
  }

  /* basically the same as Login */
  async doRegisterServerside() {
    const { username, password } = this.state;
    // MOCK
    // Call Register
    await new Promise(resolve => setTimeout(resolve, 2000));
    console.log(`Registered as ${username};${password}`);
    await this.setState({ userObject: { username, password } });
    // MOCK

    const { userObject } = this.state;
    const { onSuccessfulLogin } = this.props;
    onSuccessfulLogin(userObject);
    console.log(`logged in as ${JSON.stringify(userObject)}`);
    return true;
  }

  async doRegisterLocally() {
    const { username } = this.state;
    if (username.length === 0) {
      this.setState({ registerErrored: true, specificRegisterError: 'Username is empty' });
      return;
    }
    this.setState({ inLoading: true });
    const registerResult = await this.doRegisterServerside();
    this.setState({ inLoading: false });
    if (!registerResult) {
      this.setState({ registerErrored: true });
      return;
    }
    const { history } = this.props;
    this.setState({ inRegister: false });
    history.push('/notes');
  }

  /*
    Sets a State variable when the user is in the password field
    this is used to display the passwordStrength indicator during
    the Register form
  */
  passwordFocused() {
    this.setState({ passwordIsFocused: true });
  }

  // Blur means that the user focused something else
  passwordBlured() {
    this.setState({ passwordIsFocused: false });
  }


  render() {
    const {
      inLogin, inRegister, username, password, inLoading, loginErrored,
      registerErrored, specificLoginError, specificRegisterError, passwordIsFocused,
    } = this.state;
    return (
      <Container>
        <Grid verticalAlign="middle" columns={1} centered id="authContainer">
          <Grid.Row>
            <Grid.Column textAlign="center">
              {(!inLogin && !inRegister) && (
                <Button.Group size="massive">
                  <Button
                    onClick={this.switchToLoginState}
                    onKeyPress={this.switchToLoginState}
                    content="Login"
                  />
                  <Button.Or />
                  <Button
                    onClick={this.switchToRegisterState}
                    onKeyPress={this.switchToRegisterState}
                    content="Register"
                  />
                </Button.Group>
              )}
              {inLogin && (
                <Container>
                  <Form onSubmit={this.doLoginLocally}>
                    <Container>
                      <Form.Field inline as="h1" style={{ wordSpacing: '0.2em' }}>
                        <Icon name="arrow circle left" onClick={this.goBack} />
                        &nbsp;Login&nbsp;&nbsp;&nbsp;&nbsp;
                      </Form.Field>
                      <Form.Field inline onChange={this.handleUsernameChange}>
                        <label htmlFor="loginUsernameInput">Username</label>
                        <Input
                          placeholder="Username"
                          type="text"
                          value={username}
                          id="loginUsernameInput"
                          error={loginErrored}
                        />
                      </Form.Field>
                      <Form.Field inline onChange={this.handlePasswordChange}>
                        <label htmlFor="loginPasswordInput">Password</label>
                        <Input
                          placeholder="Password"
                          type="password"
                          value={password}
                          id="loginPasswordInput"
                          error={loginErrored}
                        />
                      </Form.Field>
                      {loginErrored && (
                        <Container textAlign="center" style={{ color: 'red', marginBottom: '10px' }}>
                          <Icon name="delete" color="red" />
                          {specificLoginError}
                        </Container>
                      )}
                      <Button
                        loading={inLoading}
                        type="submit"
                        color="green"
                        content="Login"
                        size="large"
                      />
                    </Container>
                  </Form>
                  <Segment basic style={{ width: '250px', margin: '0px auto', paddingTop: '0px' }}>
                    <Divider horizontal>OR</Divider>
                    <Button color="orange" className="basicButtonHover" icon="google" size="large" inverted />
                    <Button color="black" size="large" icon inverted>
                      <Icon name="github" color="black" />
                    </Button>
                    <Button color="blue" icon="linkedin" size="large" inverted />
                    <Button color="grey" style={{ width: '44px', height: '41px', padding: '10px' }} inverted>
                      <img src={u2fLogo} alt="u2f" style={{ width: '28px' }} />
                    </Button>
                  </Segment>
                </Container>
              )}
              {inRegister && (
                <Container>
                  <Form.Field inline as="h1" style={{ wordSpacing: '0.2em' }}>
                    <Icon
                      name="arrow circle left"
                      onClick={this.goBack}
                    />
                    &nbsp;Registration&nbsp;&nbsp;&nbsp;
                  </Form.Field>
                  <Form onSubmit={this.doRegisterLocally}>
                    <Form.Field inline onChange={this.handleUsernameChange}>
                      <label htmlFor="registerUsernameInput">Username</label>
                      <Input
                        placeholder="Username"
                        type="text"
                        value={username}
                        id="registerUsernameInput"
                        error={registerErrored}
                      />
                    </Form.Field>
                    <Form.Field inline onChange={this.handlePasswordChange}>
                      <label htmlFor="registerPasswordInput">Password</label>
                      <Input
                        placeholder="Password"
                        type="password"
                        value={password}
                        id="registerPasswordInput"
                        error={registerErrored}
                        onFocus={this.passwordFocused}
                        onBlur={this.passwordBlured}
                      />
                      <PasswordStrengthIndicator password={password} show={passwordIsFocused} />
                    </Form.Field>
                    {registerErrored && (
                      <Container textAlign="center" style={{ color: 'red', marginBottom: '10px' }}>
                        <Icon name="delete" color="red" />
                        {specificRegisterError}
                      </Container>
                    )}
                    <Button
                      loading={inLoading}
                      type="submit"
                      color="green"
                      content="Register"
                      size="large"
                    />
                  </Form>
                </Container>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

Login.defaultProps = {
};

Login.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  onSuccessfulLogin: PropTypes.func.isRequired,
};

export default Login;
