import React from 'react';
import '../LoginView/Login.css';
import {
  Icon, Progress, Container, Segment,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

/*
  The Purpose of this component is to provide a reusable component
  that can display the strength of a password
*/
class PasswordStrengthIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage: 0,
      passwordStrength: '',
      color: 'red',
      passwordSecurityLevel: -1,
    };

    this.computePasswordStrength = this.computePasswordStrength.bind(this);
  }

  // compute the strength right after the creation of this component
  componentDidMount() {
    this.computePasswordStrength();
  }

  // This fires everytime a prop (injected value) changes
  componentWillReceiveProps() {
    this.computePasswordStrength();
  }

  /*
    This function checks the password for it's strength
    the return is a container that contains the indicator container
    aswell as the color and percentage of the progressbar that is
    rendered in the render function
  */
  computePasswordStrength() {
    let tempPasswordSecurityLevel = -1;
    const { password, onSecurityLevelChanged } = this.props;
    if (password.length < 8) {
      this.setState({
        percentage: 33,
        color: 'red',
        passwordStrength:
          (
            <Container style={{ color: 'red' }}>
              <Icon name="delete" size="small" />
              Password is highly insecure
            </Container>
          ),
      });
      tempPasswordSecurityLevel = 0;
    } else if (password.length >= 8 && password.length < 12) {
      this.setState({
        percentage: 66,
        color: 'yellow',
        passwordStrength:
          (
            <Container style={{ color: 'orange' }}>
              <Icon name="warning sign" size="small" />
              Password is ok
            </Container>
          ),
      });
      tempPasswordSecurityLevel = 1;
    } else {
      this.setState({
        percentage: 100,
        color: 'green',
        passwordStrength:
          (
            <Container style={{ color: 'green' }}>
              <Icon name="check" size="small" />
              Password is secure
            </Container>
          ),
      });
      tempPasswordSecurityLevel = 2;
    }
    const { passwordSecurityLevel } = this.state;
    if (tempPasswordSecurityLevel !== passwordSecurityLevel) {
      this.setState({ passwordSecurityLevel: tempPasswordSecurityLevel }, () => {
        onSecurityLevelChanged(tempPasswordSecurityLevel);
      });
    }
  }

  render() {
    const {
      percentage, passwordStrength, color,
    } = this.state;
    const { show, password } = this.props;
    if (show && password.length !== 0) {
      return (
        <Segment
          basic
          style={{
            margin: '10px auto',
            width: '250px',
            textAlign: 'left',
            paddingLeft: '0',
            paddingTop: '4px',
          }}
        >
          <Progress percent={percentage} size="tiny" color={color} attached="top" style={{ background: 'rgba(0,0,0,.1)' }} />
          {passwordStrength}
        </Segment>
      );
    }
    return (<div />);
  }
}

PasswordStrengthIndicator.defaultProps = {
  show: true,
  onSecurityLevelChanged: () => undefined,
};

PasswordStrengthIndicator.propTypes = {
  password: PropTypes.string.isRequired,
  show: PropTypes.bool,
  onSecurityLevelChanged: PropTypes.func,
};

export default PasswordStrengthIndicator;
