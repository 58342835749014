import React from 'react';
import './Notes.css';
import 'semantic-ui-css/semantic.min.css';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import SingleNote from '../SingleNoteComponent/SingleNote';

/*
  The Purpose of this Component is to act as a container
  for Single Notes and catch updates as well as rendering the grid format and the single notes
*/
class Notes extends React.Component {
  constructor(props) {
    super(props);
    const { noteData, userObject } = this.props;
    this.state = {
      noteData,
      userObject,
      noteRefs: [],
    };

    this.noteUpdate = this.noteUpdate.bind(this);
    this.createNewNote = this.createNewNote.bind(this);
    this.childNoteEnteredEditMode = this.childNoteEnteredEditMode.bind(this);
  }

  // Handles Note updates and propagates the updates to the parent component
  noteUpdate(updatedNote) {
    const { noteData } = this.state;
    const { onNoteDataChange } = this.props;
    const newNoteData = noteData;
    let editedNote = -1;
    for (let i = 0; i < noteData.length; i += 1) {
      if (noteData[i].noteId === updatedNote.noteId) {
        newNoteData[i] = updatedNote;
        this.setState({ noteData: newNoteData }, () => {
          onNoteDataChange(newNoteData);
        });
        editedNote = i;
        break;
      }
    }
    if (noteData[editedNote].deleted) {
      const deletedNoteFromData = [...noteData];
      deletedNoteFromData.splice(editedNote, 1);
      this.setState({ noteData: deletedNoteFromData }, () => {
        onNoteDataChange(deletedNoteFromData);
      });
    }
  }

  // Handles Note creation and propagates the updates to the parent component
  createNewNote() {
    const { userObject } = this.state;
    const { onNoteDataChange } = this.props;
    const newNote = {
      noteContent: {
        noteHeading: '',
        noteText: '',
        noteCheckListContent: [
          {
            completed: false,
            listText: '',
          },
        ],
      },
      noteId: `${userObject.username}::${Date.now()}`,
      editMode: true,
    };
    this.setState(prevState => ({
      noteData: [...prevState.noteData, newNote],
    }), () => {
      const { noteData } = this.state;
      onNoteDataChange(noteData);
    });
  }

  // Callback from the child when it is focused/edited
  childNoteEnteredEditMode(noteId) {
    const { noteRefs } = this.state;
    for (let i = 0; i < noteRefs.length; i += 1) {
      if (noteRefs[i].noteId !== noteId) {
        noteRefs[i].ref.saveEdit();
      }
    }
  }

  updateRef(noteId, noteRef) {
    console.log('in');
    const { noteRefs } = this.state;
    let refUpdated = false;
    for (let i = 0; i < noteRefs.length; i += 1) {
      if (noteRefs[i].noteId === noteId) {
        noteRefs[i].ref = noteRef;
        refUpdated = true;
      }
    }
    if (!refUpdated) {
      noteRefs.push({
        noteId,
        ref: noteRef,
      });
    }
    this.setState({ noteRefs });
  }

  render() {
    const { noteData } = this.state;
    const listItems = noteData.map(d => (
      <SingleNote
        key={d.noteId}
        noteContent={{
          noteHeading: d.noteContent.noteHeading,
          noteText: d.noteContent.noteText,
          noteCheckListContent: d.noteContent.noteCheckListContent || [{ completed: false, listText: '' }],
        }}
        noteId={d.noteId}
        fontColor={d.fontColor}
        backgroundColor={d.backgroundColor}
        fontSize={d.fontSize || 1}
        editMode={d.editMode}
        update={this.noteUpdate}
        noteType={d.noteType}
        enterEditMode={this.childNoteEnteredEditMode}
        onRef={ref => (this.updateRef(d.noteId, ref))}
      />
    ));
    return (
      <div className="cardsList ui link cards">
        {listItems}
        <div className="card">
          <Button circular className="createNewNoteCard" onClick={this.createNewNote}>
            <Icon circular name="plus circle" />
          </Button>
        </div>
      </div>
    );
  }
}

Notes.propTypes = {
  onNoteDataChange: PropTypes.func.isRequired,
  userObject: PropTypes.shape({ username: PropTypes.string.isRequired }).isRequired,
  noteData: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({ noteId: PropTypes.string }),
  ])).isRequired,
};

export default Notes;
