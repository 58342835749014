import React from 'react';
import {
  Icon, Form, Input, Button, Grid, Container, Divider, Segment, Header,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import './ChangePassword.css';
import PasswordStrengthIndicator from '../PasswordStrengthIndicatorComponent/PasswordStrengthIndicator';

/*
  The Purpose of this Component is to provide a View that
  allows the user to change its password
*/
class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      repeatNewPassword: '',
      error: false,
      specificError: '',
      inLoading: false,
      newPasswordFocused: false,
      repeatNewPasswordFocused: false,
      confirmPasswordChange: false,
      passwordSecurityLevel: -1,
      preConditionsNotMet: true,
    };

    // binding functions allows access to 'this' inside the function
    this.handleOldPasswordChange = this.handleOldPasswordChange.bind(this);
    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    this.handleRepeatNewPasswordChange = this.handleRepeatNewPasswordChange.bind(this);
    this.tryToChangePassword = this.tryToChangePassword.bind(this);
    this.onSecurityLevelChanged = this.onSecurityLevelChanged.bind(this);
  }

  /*
    The following methods synchronise the input fields with the component state
    They also check the precondition on every change
  */

  onSecurityLevelChanged(securityLevel) {
    this.setState({ passwordSecurityLevel: securityLevel }, () => this.checkPreCondition());
  }

  handleOldPasswordChange(e) {
    this.setState({ oldPassword: e.target.value }, () => this.checkPreCondition());
  }

  handleNewPasswordChange(e) {
    this.setState({ newPassword: e.target.value }, () => this.checkPreCondition());
  }

  handleRepeatNewPasswordChange(e) {
    this.setState({ repeatNewPassword: e.target.value }, () => this.checkPreCondition());
  }

  /*
    This function checks if the password meets all preconditions
    Makes changes here if you want to modify the way a password is validated
  */
  checkPreCondition() {
    const {
      oldPassword, newPassword, repeatNewPassword, passwordSecurityLevel,
    } = this.state;
    if (newPassword !== repeatNewPassword) {
      this.setState({ preConditionsNotMet: true });
    } else if (oldPassword === newPassword) {
      this.setState({ preConditionsNotMet: true });
    } else if (passwordSecurityLevel < 1) {
      this.setState({ preConditionsNotMet: true });
    } else {
      this.setState({ preConditionsNotMet: false });
    }
  }

  /*
    This function asks the server to make the password change
    if the supplied old password is correct and the new password
    meets all criterias on the server side it will result in confirming the change
    if there is any errors it will be displayed by the state variable 'specific error'
  */
  async tryToChangePassword() {
    this.setState({ inLoading: true });
    const { oldPassword, newPassword } = this.state;
    const { changePassword } = this.props;
    const passwordChangeResult = await changePassword({ oldPassword, newPassword });
    if (!passwordChangeResult) {
      this.setState({ specificError: 'Incorrect old Password', error: true });
    } else {
      this.setState({ confirmPasswordChange: true });
    }
    this.setState({ inLoading: false });
  }

  render() {
    const {
      oldPassword, newPassword, repeatNewPassword, error, specificError, inLoading,
      newPasswordFocused, repeatNewPasswordFocused, confirmPasswordChange, preConditionsNotMet,
    } = this.state;

    const matchedPasswordsNotice = (newPassword === repeatNewPassword)
      ? (
        <Container textAlign="center" style={{ color: 'green', marginBottom: '10px' }}>
          <Icon name="check" color="green" />
          Passwords match
        </Container>
      ) : (
        <Container textAlign="center" style={{ color: 'red', marginBottom: '10px' }}>
          <Icon name="delete" color="red" />
          Passwords do not match
        </Container>
      );

    return (
      <Container>
        <Grid verticalAlign="middle" columns={1} centered id="infoContainer">
          <Grid.Row>
            <Grid.Column textAlign="center" style={{ width: '320px', padding: '0px' }}>
              <Segment style={{ padding: '7px' }}>
                <Header>
                  Change your Password
                </Header>
                <Divider />
                <Form onSubmit={this.tryToChangePassword}>
                  <Container>
                    <Form.Field inline onChange={this.handleOldPasswordChange}>
                      <label htmlFor="oldPasswordInput" className="inputLabel">Old</label>
                      <Input
                        placeholder="Old Password"
                        disabled={inLoading || confirmPasswordChange}
                        type="password"
                        value={oldPassword}
                        id="oldPasswordInput"
                        error={error}
                      />
                    </Form.Field>
                    <Form.Field inline onChange={this.handleNewPasswordChange}>
                      <label htmlFor="newPasswordInput" className="inputLabel">New</label>
                      <Input
                        placeholder="New Password"
                        disabled={inLoading || confirmPasswordChange}
                        type="password"
                        value={newPassword}
                        id="newPasswordInput"
                        error={error}
                        onFocus={() => this.setState({ newPasswordFocused: true })}
                        onBlur={() => this.setState({ newPasswordFocused: false })}
                      />
                      <PasswordStrengthIndicator
                        password={newPassword}
                        show={newPasswordFocused}
                        onSecurityLevelChanged={this.onSecurityLevelChanged}
                      />
                    </Form.Field>
                    <Form.Field inline onChange={this.handleRepeatNewPasswordChange}>
                      <label htmlFor="repeatPasswordInput" className="inputLabel">Repeat</label>
                      <Input
                        placeholder="Repeat new Password"
                        disabled={inLoading || confirmPasswordChange}
                        type="password"
                        value={repeatNewPassword}
                        id="repeatPasswordInput"
                        error={error}
                        onFocus={() => this.setState({ repeatNewPasswordFocused: true })}
                        onBlur={() => this.setState({ repeatNewPasswordFocused: false })}
                      />
                      {repeatNewPasswordFocused
                        && (
                          matchedPasswordsNotice
                        )
                      }
                    </Form.Field>
                    {error && (
                      <Container textAlign="center" style={{ color: 'red', marginBottom: '10px' }}>
                        <Icon name="delete" color="red" />
                        {specificError}
                      </Container>
                    )}
                    <Button
                      disabled={confirmPasswordChange || preConditionsNotMet}
                      loading={inLoading}
                      type="submit"
                      color="green"
                      content={confirmPasswordChange ? <Icon name="check" /> : 'Change Password'}
                      size="large"
                    />
                  </Container>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

ChangePassword.defaultProps = {
};

ChangePassword.propTypes = {
  changePassword: PropTypes.func.isRequired,
};

export default ChangePassword;
