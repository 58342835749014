import React from 'react';
import {
  Icon, Form, Input, Button, Grid, Container, Divider, Segment, Header,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import u2fLogo from '../../u2f.png';
import 'semantic-ui-css/semantic.min.css';
import './Account.css';

/*
  The Purpose of this Component is to provide a View that
  allows the user to change its account details
*/
class Account extends React.Component {
  constructor(props) {
    // the userObject is injected into this component
    // the userObject is read only and can only be modified using
    // the function 'updateUserInformation' which is also delivered by the props
    // the 'updateUserInformation' propagates the change to the parent component
    const { userObject } = props;
    super(props);
    this.state = {
      userObject: {
        username: userObject.username,
        password: userObject.password,
        firstName: userObject.firstName || '',
        lastName: userObject.lastName || '',
        email: userObject.email || '',
      },
      error: false,
      specificError: '',
      inLoading: false,
    };

    // binding functions allows access to 'this' inside the function
    this.handleEmailFieldChange = this.handleEmailFieldChange.bind(this);
    this.handleFirstNameFieldChange = this.handleFirstNameFieldChange.bind(this);
    this.handleLastNameFieldChange = this.handleLastNameFieldChange.bind(this);
    this.updateUserInformation = this.updateUserInformation.bind(this);
    this.navigateToChangePasswordView = this.navigateToChangePasswordView.bind(this);
  }

  /*
    Those listeners synchronise the values of the Input fields with the component state
    assign the typed email to the user object
  */
  handleEmailFieldChange(e) {
    const { userObject } = this.state;
    Object.assign(userObject, { email: e.target.value });
    this.setState({ userObject });
  }

  handleFirstNameFieldChange(e) {
    const { userObject } = this.state;
    Object.assign(userObject, { firstName: e.target.value });
    this.setState({ userObject });
  }

  handleLastNameFieldChange(e) {
    const { userObject } = this.state;
    Object.assign(userObject, { lastName: e.target.value });
    this.setState({ userObject });
  }

  // This packs local changes and commits them to the parent component
  updateUserInformation() {
    this.setState({ inLoading: true });
    const { userObject } = this.state;
    const { updateUserInformation } = this.props;
    updateUserInformation(userObject);
    setTimeout(() => this.setState({ inLoading: false }), 2000);
  }

  navigateToChangePasswordView() {
    const { history } = this.props;
    history.push('/changePassword');
  }

  render() {
    const {
      userObject, error, specificError, inLoading,
    } = this.state;
    return (
      <Container>
        <Grid verticalAlign="middle" columns={1} centered id="infoContainer">
          <Grid.Row>
            <Grid.Column textAlign="center" style={{ width: '320px', padding: '0px' }}>
              <Segment style={{ padding: '7px' }}>
                <Header>
                  {userObject.username}
                </Header>
                <Divider horizontal>Update Details</Divider>
                <Form onSubmit={this.updateUserInformation}>
                  <Container>
                    <Form.Field inline onChange={this.handleEmailFieldChange}>
                      <label htmlFor="emailInput" className="inputLabel">E-Mail</label>
                      <Input
                        disabled={inLoading}
                        placeholder="E-Mail"
                        type="email"
                        value={userObject.email}
                        id="emailInput"
                        error={error}
                      />
                    </Form.Field>
                    <Form.Field inline onChange={this.handleFirstNameFieldChange}>
                      <label htmlFor="firstNameInput" className="inputLabel">First name</label>
                      <Input
                        disabled={inLoading}
                        placeholder="First name"
                        type="text"
                        value={userObject.firstName}
                        id="firstNameInput"
                        error={error}
                      />
                    </Form.Field>
                    <Form.Field inline onChange={this.handleLastNameFieldChange}>
                      <label htmlFor="firstNameInput" className="inputLabel">Last name</label>
                      <Input
                        disabled={inLoading}
                        placeholder="Last Name"
                        type="text"
                        value={userObject.lastName}
                        id="firstNameInput"
                        error={error}
                      />
                    </Form.Field>
                    {error && (
                      <Container textAlign="center" style={{ color: 'red', marginBottom: '10px' }}>
                        <Icon name="delete" color="red" />
                        {specificError}
                      </Container>
                    )}
                    <Button
                      disabled={inLoading}
                      type="submit"
                      color="green"
                      content={inLoading ? <Icon name="check" /> : 'Save'}
                      size="large"
                    />
                  </Container>
                </Form>
                <Divider horizontal>Or</Divider>
                <Button
                  content="Change Password"
                  basic
                  color="red"
                  id="passwordResetButton"
                  onClick={this.navigateToChangePasswordView}
                />
                <Divider horizontal>
                  <Icon name="sign-in" />
                  Providers
                </Divider>
                <Button.Group vertical>
                  <Button content="Google" icon="google" color="orange" />
                  <Button content="LinkedIn" icon="linkedin" color="linkedin" />
                  <Button content="GitHub" icon="github" color="black" />
                  <Button color="grey">
                    <img src={u2fLogo} alt="u2f" style={{ width: '26px' }} />
                    &nbsp;&nbsp;&nbsp;Fido U2F
                  </Button>
                </Button.Group>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

Account.defaultProps = {
};

Account.propTypes = {
  userObject: PropTypes.shape({ username: PropTypes.string.isRequired }).isRequired,
  updateUserInformation: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default Account;
