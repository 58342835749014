import React from 'react';
import {
  Menu, Dropdown, Icon, Modal, TextArea, Button,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './InfoBar.css';

/*
  This Component provides the Navigationbar to every View
*/
class InfoBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exportModalShown: false,
    };
  }

  render() {
    const {
      userObject, logoutFunction, history, noteData,
    } = this.props;
    const { exportModalShown } = this.state;
    return (
      <Menu
        attached="top"
        icon="labeled"
        inverted
        style={{ borderRadius: '0px', marginRight: '0px', marginLeft: '0px' }}
      >

        <Menu.Item header>Notekeeper</Menu.Item>
        <Menu.Item
          onClick={this.handleItemClick}
          style={{ padding: '0' }}
        >
          <Dropdown
            style={{ width: '100%', height: '100%', padding: '.92857143em 1.14285714em' }}
            text={userObject.username}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  logoutFunction();
                  history.push('/');
                }}
              >
                <Icon name="sign-out" />
                Logout
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  history.push('/preferences');
                }}
              >
                <Icon name="sliders horizontal" />
                Preferences
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  history.push('/account');
                }}
              >
                <Icon name="shield" />
                Account
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  history.push('/notes');
                }}
              >
                <Icon name="sticky note" />
                Notes
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ exportModalShown: true });
                }}
              >
                <Icon name="download" />
                Export Notes
              </Dropdown.Item>
              <Modal
                size="small"
                open={exportModalShown}
                onClose={() => this.setState({ exportModalShown: false })}
                onClick={e => e.stopPropagation()}
              >
                <Modal.Header>Copy the following Data</Modal.Header>
                <Modal.Content>
                  <TextArea
                    rows={15}
                    disabled
                    style={{ width: '100%', height: '100%' }}
                    value={JSON.stringify(noteData)}
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    positive
                    icon="checkmark"
                    content="Done"
                    onClick={() => this.setState({ exportModalShown: false })}
                  />
                </Modal.Actions>
              </Modal>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Menu>
    );
  }
}

InfoBar.defaultProps = {
};

InfoBar.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  logoutFunction: PropTypes.func.isRequired,
  userObject: PropTypes.shape({ username: PropTypes.string.isRequired }).isRequired,
  noteData: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({ noteId: PropTypes.string }),
  ])).isRequired,
};


export default InfoBar;
