import noteTypes from '../NoteTypes';

const mockNotes = [{
  noteId: '1::TEST_USER',
  noteContent: {
    noteHeading: 'Urlaub',
    noteText: '\n1. Grand Rapids/USA\n2. Tokio/Japan\n3. Freiburg/Deutschland\n4. Seattle/USA',
  },
  fontColor: '#ffffff',
  backgroundColor: '#000000',
  noteType: noteTypes.freeText,
},
{
  noteId: '2::TEST_USER',
  noteContent: {
    noteHeading: 'Projekte',
    noteText: 'Notekeeping\nCoinkit\nWebsites\nMinecraft Mainframes',
  },
  noteType: noteTypes.freeText,
},
{
  noteId: '3::TEST_USER',
  noteContent: {
    noteHeading: 'Serien',
    noteCheckListContent: [
      {
        completed: true,
        listText: 'Death Note',
      },
      {
        completed: false,
        listText: 'Fairy Tale',
      },
      {
        completed: false,
        listText: 'No game no life',
      },
      {
        completed: true,
        listText: 'the irregular at Magical Highschool',
      },
    ],
  },
  fontColor: '#ffffff',
  backgroundColor: '#673ab7',
  noteType: noteTypes.checkList,
  fontSize: 0.8,
},
{
  noteId: '4::TEST_USER',
  noteContent: {
    noteHeading: '',
    noteText: 'Keyboard spielen\nTrainieren\nApex\nCoden',
  },
  noteType: noteTypes.freeText,
},
{
  noteId: '5::TEST_USER',
  noteContent: {
    noteHeading: 'Dates',
    noteText: '18.02.2019\n04.03.2019\n13.04.2019\n01.04.2019\n23.04.2019',
  },
  noteType: noteTypes.freeText,
},
{
  noteId: '6::TEST_USER',
  noteContent: {
    noteHeading: 'Only Heading no Body',
    noteText: '',
  },
  noteType: noteTypes.freeText,
},
{
  noteId: '7::TEST_USER',
  noteContent: {
    noteHeading: 'Colored without Body',
    noteText: '',
  },
  noteType: noteTypes.freeText,
  fontColor: '#ffffff',
  backgroundColor: '#607d8b',
},
{
  noteId: '8::TEST_USER',
  noteContent: {
    noteHeading: '',
    noteText: 'Colored without Head',
  },
  noteType: noteTypes.freeText,
  fontColor: '#ffffff',
  backgroundColor: '#21963f',
},
{
  noteId: '9::TEST_USER',
  noteContent: {
    noteHeading: 'New Blank Note with Head',
  },
  noteType: noteTypes.new,
},
{
  noteId: '10::TEST_USER',
  noteContent: {
    noteHeading: '',
    noteText: '',
  },
  noteType: noteTypes.new,
},
{
  noteId: '11::TEST_USER',
  noteContent: {
    noteHeading: 'Checked List',
    noteCheckListContent: [
      {
        completed: true,
        listText: 'Cleanup Code',
      },
      {
        completed: true,
        listText: 'Deploy on Server',
      },
      {
        completed: true,
        listText: 'Make Money',
      },
    ],
  },
  noteType: noteTypes.checkList,
},
{
  noteId: '12::TEST_USER',
  noteContent: {
    noteHeading: 'Unchecked List',
    noteCheckListContent: [
      {
        completed: false,
        listText: 'Cleanup Code',
      },
      {
        completed: false,
        listText: 'Deploy on Server',
      },
      {
        completed: false,
        listText: 'Make Money',
      },
    ],
  },
  noteType: noteTypes.checkList,
},
{
  noteId: '13::TEST_USER',
  noteContent: {
    noteHeading: 'Mixed List',
    noteCheckListContent: [
      {
        completed: false,
        listText: 'Cleanup Code',
      },
      {
        completed: true,
        listText: 'Deploy on Server',
      },
      {
        completed: false,
        listText: 'Make Money',
      },
    ],
  },
  noteType: noteTypes.checkList,
},
{
  noteId: '14::TEST_USER',
  noteContent: {
    noteHeading: 'Colored Mixed List',
    noteCheckListContent: [
      {
        completed: false,
        listText: 'Cleanup Code',
      },
      {
        completed: true,
        listText: 'Deploy on Server',
      },
      {
        completed: false,
        listText: 'Make Money',
      },
    ],
  },
  noteType: noteTypes.checkList,
  fontColor: '#ffffff',
  backgroundColor: '#21963f',
},
{
  noteId: '15::TEST_USER',
  noteContent: {
    noteHeading: 'Inverted Mixed List',
    noteCheckListContent: [
      {
        completed: false,
        listText: 'Cleanup Code',
      },
      {
        completed: true,
        listText: 'Deploy on Server',
      },
      {
        completed: false,
        listText: 'Make Money',
      },
    ],
  },
  noteType: noteTypes.checkList,
  fontColor: '#ffffff',
  backgroundColor: '#000000',
},
{
  noteId: '16::TEST_USER',
  noteContent: {
    noteHeading: 'Big Note',
    noteText: 'This text is larger than normal',
  },
  noteType: noteTypes.freeText,
  fontSize: 1.5,
},
{
  noteId: '17::TEST_USER',
  noteContent: {
    noteHeading: 'Small Note',
    noteText: 'This text is smaller than normal',
  },
  noteType: noteTypes.freeText,
  fontSize: 0.8,
},
{
  noteId: '18::TEST_USER',
  noteContent: {
    noteHeading: 'Big inverted List',
    noteCheckListContent: [
      {
        completed: true,
        listText: 'This List',
      },
      {
        completed: false,
        listText: 'Is Larger',
      },
      {
        completed: false,
        listText: 'than normal',
      },
    ],
  },
  noteType: noteTypes.checkList,
  fontColor: '#ffffff',
  backgroundColor: '#000000',
  fontSize: 1.4,
},
{
  noteId: '19::TEST_USER',
  noteContent: {
    noteHeading: 'Small List',
    noteCheckListContent: [
      {
        completed: false,
        listText: 'This List',
      },
      {
        completed: true,
        listText: 'Is smaller',
      },
      {
        completed: true,
        listText: 'than normal',
      },
    ],
  },
  noteType: noteTypes.checkList,
  fontSize: 0.8,
},
];

export default mockNotes;
