import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Login from './components/LoginView/Login';
import Notes from './components/NotesView/Notes';
import InfoBar from './components/InfoBarComponent/InfoBar';
import Account from './components/AccountView/Account';
import ChangePassword from './components/ChangePasswordView/ChangePassword';
import mockNotes from './Mock/mockNotes';

/*
  This component functions as two things
  - Primary data Store: All data is garanteed to be up to date
  - Router for the application
  Network code should be injected here
*/
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noteData: mockNotes,
      userObject: {
        username: 'Totenfluch_TODO', // TODO: Replace later
      },
    };

    this.onSuccessfulLogin = this.onSuccessfulLogin.bind(this);
    this.updateUserInformation = this.updateUserInformation.bind(this);
    this.onNoteDataChange = this.onNoteDataChange.bind(this);
    this.logout = this.logout.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  onSuccessfulLogin(newUserObject) {
    this.setState({ userObject: newUserObject });
    console.log(`New UserObject: ${JSON.stringify(newUserObject)}`);
  }

  onNoteDataChange(newNoteData) {
    this.setState({ noteData: newNoteData });
    console.log(`New NoteData: ${JSON.stringify(newNoteData)}`);
    // TODO: UPDATE REMOTE SERVER?
  }

  updateUserInformation(newUserObject) {
    this.setState({ userObject: newUserObject });
    console.log(`New UserObject: ${JSON.stringify(newUserObject)}`);
    // TODO: UPDATE REMOTE SERVER
  }

  async changePassword(passwordObject) {
    const { oldPassword, newPassword } = passwordObject;
    // MOCK
    // Call Remote ChangePassword
    // TODO UPDATE REMOTE SERVER
    await new Promise(resolve => setTimeout(resolve, 2000));
    console.log(`Changed password from ${oldPassword} to ${newPassword}`);
    const { userObject } = this.state;
    Object.assign(userObject, { newPassword });
    await this.setState({ userObject });
    // MOCK

    return true;
  }

  logout() {
    this.setState({ userObject: {} });
  }

  render() {
    const { userObject, noteData } = this.state;
    return (
      <Router>
        <div>
          <Route
            exact
            path="/notes"
            render={props => (
              <div>
                <InfoBar
                  {...props}
                  logoutFunction={this.logout}
                  userObject={userObject}
                  noteData={noteData}
                />
                <Notes
                  noteData={noteData}
                  userObject={userObject}
                  onNoteDataChange={this.onNoteDataChange}
                />
              </div>
            )}
          />
          <Route
            exact
            path="/"
            render={props => (
              <Login {...props} onSuccessfulLogin={this.onSuccessfulLogin} />
            )}
          />
          <Route
            exact
            path="/account"
            render={props => (
              <div>
                <InfoBar
                  {...props}
                  logoutFunction={this.logout}
                  userObject={userObject}
                  noteData={noteData}
                />
                <Account
                  {...props}
                  userObject={userObject}
                  updateUserInformation={this.updateUserInformation}
                />
              </div>
            )}
          />
          <Route
            exact
            path="/changePassword"
            render={props => (
              <div>
                <InfoBar
                  {...props}
                  logoutFunction={this.logout}
                  userObject={userObject}
                  noteData={noteData}
                />
                <ChangePassword
                  {...props}
                  changePassword={this.changePassword}
                />
              </div>
            )}
          />
        </div>
      </Router>
    );
  }
}

export default Main;
