import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import PropTypes from 'prop-types';
import { CirclePicker } from 'react-color';
import { Slider } from 'react-semantic-ui-range';
import {
  Popup, Card, Label, Icon, Divider,
} from 'semantic-ui-react';
import SingleNoteContent from './SingleNoteContent';

/*
  The purpose of this component is to create a reusable
  component for a single note. This component contains only
  the note itself and the buttons to modify it but not the content itself
*/
class SingleNote extends React.Component {
  constructor(props) {
    super(props);
    const {
      deleted, noteContent, noteId, editMode, update, backgroundColor, fontColor,
      fontSize, noteType,
    } = props;
    this.state = {
      deleted,
      noteContent,
      noteId,
      editMode,
      updateParent: update,
      editBackgroundColor: false,
      currentBackgroundColor: backgroundColor,
      editFontColor: false,
      currentFontColor: fontColor,
      editFontSize: false,
      currentFontSizeModifier: fontSize,
      fontSizeSliderValue: (fontSize * 5),
      noteType,
    };

    this.startEditMode = this.startEditMode.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleSingleNoteStateChange = this.handleSingleNoteStateChange.bind(this);
    this.handleSingleNoteDirectStateChange = this.handleSingleNoteDirectStateChange.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
    this.toggleBackgroundColorPicker = this.toggleBackgroundColorPicker.bind(this);
    this.handleBackgroundColorChange = this.handleBackgroundColorChange.bind(this);
    this.toggleFontColorPicker = this.toggleFontColorPicker.bind(this);
    this.handleFontColorChange = this.handleFontColorChange.bind(this);
    this.toggleFontSize = this.toggleFontSize.bind(this);
    this.onFontSizeChange = this.onFontSizeChange.bind(this);
  }

  // Send ref to parent so we can call child methods from the parent
  componentDidMount() {
    const { onRef } = this.props;
    onRef(this);
  }

  // Computes the Font size based on the Textsize Slider value
  onFontSizeChange(value) {
    this.setState({ currentFontSizeModifier: ((value * 2) / 10), fontSizeSliderValue: value });
  }

  // Toggles the Textsize slider at the bottom of the note
  toggleFontSize() {
    const { editFontSize } = this.state;
    this.setState({
      editFontSize: !editFontSize,
      editFontColor: false,
      editBackgroundColor: false,
    });
  }

  // Toggles the Background ColorPicker at the bottom of the note
  toggleBackgroundColorPicker() {
    const { editBackgroundColor } = this.state;
    this.setState({
      editBackgroundColor: !editBackgroundColor,
      editFontColor: false,
      editFontSize: false,
    });
  }

  // Toggles the Font ColorPicker at the bottom of the note
  toggleFontColorPicker() {
    const { editFontColor } = this.state;
    this.setState({
      editFontColor: !editFontColor,
      editBackgroundColor: false,
      editFontSize: false,
    });
  }

  // Fires when a color is clicked in the Background ColorPicker
  // Updates the background color of the Note directly
  handleBackgroundColorChange(color) {
    this.setState({
      currentBackgroundColor: color.hex,
    });
  }

  // Fires when a color is clicked in the Font ColorPicker
  // Updates the font color of the Note directly
  handleFontColorChange(color) {
    this.setState({
      currentFontColor: color.hex,
    });
  }

  // Fires when the Title of a note was changed
  handleTitleChange(e) {
    this.setState({
      noteContent: { noteHeading: e.target.value },
    });
  }

  /*
    This fires when the internal Single Note that contains the content
    propagates it's changes to this component (the parent)
    (!) This method only changes the local state
  */
  handleSingleNoteStateChange(singleNoteContentState) {
    const { noteContent } = this.state;
    this.setState({
      noteContent: {
        noteText: singleNoteContentState.noteContent.noteText,
        noteCheckListContent: singleNoteContentState.noteContent.noteCheckListContent,
        // TODO: remove bellow and replace
        noteHeading: noteContent.noteHeading,
        // TODO: noteHeading: singleNoteContentState.noteContent.noteHeading,
      },
      noteType: singleNoteContentState.noteType,
    });
  }

  /*
    This fires when the internal Single Note that contains the content
    propagates it's changes to this component (the parent)
    (!) This method modifies the parent state and directly syncs with the main datastorage
        This is require for changes that don't involve hitting the 'save' button
        An example usage of this method is the ticking of a checkBox in the List Note Type
  */
  handleSingleNoteDirectStateChange(singleNoteContentState) {
    const { noteContent, updateParent } = this.state;
    this.setState({
      noteContent: {
        noteText: singleNoteContentState.noteContent.noteText,
        noteCheckListContent: singleNoteContentState.noteContent.noteCheckListContent,
        // TODO: remove bellow and replace
        noteHeading: noteContent.noteHeading,
        // TODO: noteHeading: singleNoteContentState.noteContent.noteHeading,
      },
      noteType: singleNoteContentState.noteType,
    }, () => {
      updateParent(this.state);
    });
  }

  // Makes the note Editable and tells parent to save other notes with enterEditMode()
  startEditMode() {
    const { editMode, noteId } = this.state;
    if (editMode) {
      return;
    }
    this.setState({
      editMode: true,
    });
    const { enterEditMode } = this.props;
    enterEditMode(noteId);
  }

  // Propagates all updates of the note to the Parent component
  saveEdit() {
    const { updateParent, editMode } = this.state;
    // If Note is not beeing edited, don't save
    if (!editMode) {
      return;
    }
    this.setState({
      editBackgroundColor: false,
      editMode: false,
      editFontColor: false,
    }, () => {
      updateParent(this.state);
    });
  }

  // Propagates the deletion of a note to the Parent component
  deleteNote() {
    const { updateParent } = this.state;
    this.setState({
      deleted: true,
    }, () => {
      updateParent(this.state);
    });
  }

  render() {
    const {
      editMode, noteContent, currentBackgroundColor, editBackgroundColor,
      editFontColor, currentFontColor, editFontSize, currentFontSizeModifier,
      fontSizeSliderValue, noteType, noteId,
    } = this.state;

    // Replaces Blank headers with placeholders
    const replacedNoteHeading = noteContent.noteHeading || (null);

    return (
      <Card className="cardDepth" style={{ backgroundColor: currentBackgroundColor }} onClick={this.startEditMode} onKeyDown={this.startEditMode} role="button" tabIndex="0">
        <Card.Content>
          <Card.Header style={{ color: currentFontColor, fontSize: `${0.4 + currentFontSizeModifier}em` }}>
            {editMode
              ? (
                <div className="ui input transparent noteHeading">
                  <input
                    type="text"
                    placeholder="Title..."
                    value={noteContent.noteHeading}
                    onChange={this.handleTitleChange.bind(this)}
                    style={{
                      color: currentFontColor,
                      backgroundColor: currentBackgroundColor,
                      fontWeight: 700,
                      border: '0px',
                    }}
                  />
                </div>
              ) : (
                replacedNoteHeading
              )
            }
          </Card.Header>
          {(replacedNoteHeading || editMode) && (<Divider />)}
          <Card.Description className="noteText" style={{ color: currentFontColor, fontSize: `${currentFontSizeModifier}em` }}>
            <SingleNoteContent
              editMode={editMode}
              noteType={noteType}
              noteId={noteId}
              noteContent={noteContent}
              onChange={this.handleSingleNoteStateChange}
              onDirectChange={this.handleSingleNoteDirectStateChange}
              currentFontColor={currentFontColor}
              currentBackgroundColor={currentBackgroundColor}
              currentFontSizeModifier={currentFontSizeModifier}
            />
          </Card.Description>
        </Card.Content>
        {editMode
          && (
            <Card.Content extra style={{ backgroundColor: 'white' }}>
              <span className="right floated">
                <Popup trigger={<Icon name="text height" size="large" onClick={this.toggleFontSize} onKeyDown={this.toggleFontSize} role="button" tabIndex="0" />} size="tiny" content="Change font size" />
                <Popup trigger={<Icon name="tint" size="large" onClick={this.toggleBackgroundColorPicker} onKeyDown={this.toggleBackgroundColorPicker} role="button" tabIndex="0" />} size="tiny" content="Change background color" />
                <Popup trigger={<Icon name="paint brush" size="large" onClick={this.toggleFontColorPicker} onKeyDown={this.toggleFontColorPicker} role="button" tabIndex="0" />} size="tiny" content="Change font color" />
                <Popup trigger={<Icon name="check circle" size="large" onClick={this.saveEdit} onKeyDown={this.saveEdit} role="button" tabIndex="0" />} size="tiny" content="Save note" />
              </span>
              <span className="left floated">
                <Popup trigger={<Icon name="trash" size="large" onClick={this.deleteNote} onKeyDown={this.deleteNote} role="button" tabIndex="0" />} size="tiny" content="Delete note" />
              </span>
              {editBackgroundColor
                && (
                  <Label pointing="above">
                    <CirclePicker
                      color={currentBackgroundColor}
                      onChangeComplete={this.handleBackgroundColorChange}
                      colors={['#ffffff', '#000000', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#21963f', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b']}
                    />
                  </Label>
                )
              }
              {editFontColor
                && (
                  <Label pointing="above">
                    <CirclePicker
                      color={currentFontColor}
                      onChangeComplete={this.handleFontColorChange}
                      colors={['#000000', '#ffffff', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#21963f', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b']}
                    />
                  </Label>
                )
              }
              {editFontSize
                && (
                  <Label pointing="above" className="maxWidth">
                    <Slider
                      color="grey"
                      inverted={false}
                      settings={{
                        start: fontSizeSliderValue,
                        min: 3,
                        max: 8,
                        step: 1,
                        onChange: this.onFontSizeChange,
                      }}
                    />
                  </Label>
                )
              }
            </Card.Content>
          )
        }
      </Card>
    );
  }
}

SingleNote.defaultProps = {
  deleted: false,
  noteContent: {},
  noteId: '-1',
  editMode: false,
  backgroundColor: '#ffffff',
  fontColor: '#000000',
  fontSize: 1,
  noteType: 'new',
};

SingleNote.propTypes = {
  deleted: PropTypes.bool,
  noteContent: PropTypes.shape({ noteHeading: PropTypes.string }),
  noteId: PropTypes.string,
  editMode: PropTypes.bool,
  update: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  fontSize: PropTypes.number,
  noteType: PropTypes.string,
  enterEditMode: PropTypes.func.isRequired,
  onRef: PropTypes.func.isRequired,
};


export default SingleNote;
